import { FORCE_TEST_ENDPOINT } from './config';
import { ForceTestResponse } from './types';

export const forceTest = async (urlId: number): Promise<ForceTestResponse> => {
	const apiUrl = `${FORCE_TEST_ENDPOINT}?urlId=${urlId}`;

	const response = await fetch(apiUrl);

	if (!response.ok) {
		throw new Error(`Request to "${apiUrl}" failed. Status: ${response.status}.`);
	}

	return await response.json();
};
