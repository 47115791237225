import { Layout } from '../components/ui/Layout';
import React from 'react';
import { AdminTable } from '../components/organisms/AdminTable';
import { getHashFromPath } from '../utils/getHashFromPath';
import loadable from '@loadable/component';

const Helmet = loadable(() => import('react-helmet'));
const Header = loadable(() => import('../components/layout/Header'));
const Paper = loadable(() => import('../components/layout/Paper'));

export default ({ location: { pathname } }) => {
	const page = getHashFromPath(pathname);
	return (
		<Layout>
			<Helmet>
				<title>PageSpeed.cz - Administrace</title>
			</Helmet>
			<Header />
			<Paper>
				<AdminTable page={Number(page)} />
			</Paper>
		</Layout>
	);
};
