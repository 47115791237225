import { FormattedDate, FormattedTime } from 'gatsby-plugin-intl';
import React, { FC, useEffect, useState } from 'react';
import { getAdminData } from '../../api/getAdminData';
import { GetAdminDataResponse } from '../../api/types';
import { Link } from 'gatsby';
import { forceTest } from '../../api/forceTest';
import { RowMain } from '../layout/RowMain';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

interface Props {
	page?: number;
}

export const AdminTable: FC<Props> = ({ page }) => {
	page = page ? page : 0;
	const [data, setData] = useState<GetAdminDataResponse>({
		page: page,
		tests: [],
	});

	useEffect(() => {
		(async () => {
			const tests = await getAdminData(page);
			setData(tests);
		})();
	}, [page]);

	return (
		<RowMain>
			<table>
				<thead>
					<tr>
						<th>Test</th>
						<th>Url adresy</th>
					</tr>
				</thead>
				<tbody>
					{data.tests.map(test => (
						<tr key={test.id}>
							<td>
								<strong>Hash</strong>:{' '}
								<Link to={`/r/${test.hash}`} target="_blank">
									{test.hash}
								</Link>{' '}
								<br />
								<strong>Datum</strong>: <FormattedDate value={test.date} />{' '}
								<FormattedTime value={test.date} />
							</td>
							<td>
								{test.urls.map(url => (
									<div key={url.id} style={{ wordBreak: 'break-all' }}>
										<OutboundLink href={url.url}>{url.url}</OutboundLink>{' '}
										<button type="button" onClick={() => forceTest(url.id)}>
											Vynutit test
										</button>
									</div>
								))}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<div className="grid">
				<div className="grid__cell size--6-12">
					{data.page > 0 && (
						<Link to={data.page === 1 ? '/admin' : `/admin/${data.page - 1}`}>Předchozí stránka</Link>
					)}
				</div>
				<div className="grid__cell size--6-12 u-text-right">
					{data.tests.length > 0 && <Link to={`/admin/${data.page + 1}`}>Další stránka</Link>}
				</div>
			</div>
		</RowMain>
	);
};
