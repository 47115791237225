import { GET_ADMIN_DATA_ENDPOINT } from './config';
import { GetAdminDataResponse } from './types';

export const getAdminData = async (page: number): Promise<GetAdminDataResponse> => {
	const apiUrl = `${GET_ADMIN_DATA_ENDPOINT}?page=${page}`;

	const response = await fetch(apiUrl);

	if (!response.ok) {
		throw new Error(`Request to "${apiUrl}" failed. Status: ${response.status}.`);
	}

	return await response.json();
};
